/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import { useHistory } from 'react-router-dom';
import {
  StepHeader, PlayerForm, Cart, ResponsiveContainer,
} from '../../components';
import { useCreateAccountCoordinator } from '../../view-coordinators';
import { AssignMembershipForm, CreateAccountForm } from './components';
import CreatePlayerBaseScreen from './components/step1/CreatePlayerBaseScreen';
import { WithHeader } from '../../hocs';
import AssignMembershipsBaseScreen from './components/step2/AssignMembershipsBaseScreen';
import styles from './styles.module.scss';
import { HOME_URL } from '../../constants/routes';

const CreateAccount: React.FC = () => {
  const history = useHistory();
  const view = useCreateAccountCoordinator();

  useEffect( () => {
    if ( view.redirectToHome ) {
      history.replace( HOME_URL );
    }
  }, [ view.redirectToHome ] );

  const renderMainDescription = () => (
    view.currentStep === 2
      ? (
        <p className={cn( styles.mainDesc, styles.body1 )}>
          You can create more than one player and assign sessions to them
        </p>
      )
      : null
  );

  const earlyFinishAccountCreation = async () => {
    if ( !view.isCreatingAccount || !view.isCreatingPlayer ) {
      await view.earlyFinishAccountCreation();
      history.replace( HOME_URL );
    }
  };

  const headerByStep = [
    'Welcome to IVL',
    '',
    'Create Players',
    '',
    'Assign memberships',
    'Cart',
  ];

  const stepHeaderByStep = [
    () => null,
    () => null,
    () => (
      <StepHeader
        className={styles.stepContainer}
        currentStep={1}
        onClick={() => view.goToAccountInformation()}
      />
    ),
    () => null,
    () => (
      <StepHeader
        className={styles.stepContainer}
        currentStep={2}
        onClick={() => view.goToPlayersInformation()}
      />
    ),
    () => (
      <StepHeader
        className={styles.stepContainer}
        currentStep={3}
        onClick={() => view.goToAssignMemberships()}
      />
    ),
  ];

  const componentByStep = [
    () => (
      <CreateAccountForm
        onSubmit={view.saveAccountInformation}
        facilities={view.facilities}
        initialValues={view.accountInformation}
        errorsValues={view.errorValues}
      />
    ),
    () => (
      <CreatePlayerBaseScreen
        goToCreatePlayers={view.goToCreatePlayers}
      />
    ),
    () => (
      <PlayerForm
        onSubmit={view.savePlayersInformation}
        initialValues={{ players: view.playersInformation }}
      />
    ),
    () => (
      <AssignMembershipsBaseScreen
        goToSeeMemberships={view.goToSeeMemberships}
      />
    ),
    () => (
      <AssignMembershipForm
        goToCart={view.goToCart}
        membershipPlans={view.membershipPlans}
        playersDropdownInfo={view.selectablePlayers}
        onSelectPlayer={view.handleSelectPlayer}
        onRemovePlayer={view.handleRemovePlayer}
        onEarlyFinish={earlyFinishAccountCreation}
      />
    ),
    () => (
      <Cart
        view={view.cartView}
        beforeGoToPay={view.beforeGoToPay}
      />
    ),
  ];

  const containerStyle = cn(
    styles.container,
    { [styles.transition]: view.currentStep === 1 || view.currentStep === 3 },
  );

  return (
    <ResponsiveContainer sectionStyle={containerStyle}>
      {stepHeaderByStep[view.currentStep]()}
      <h1 className={cn( styles.mainHeader, styles.megaHeader )}>
        {headerByStep[view.currentStep]}
      </h1>
      {renderMainDescription()}
      {componentByStep[view.currentStep]()}
    </ResponsiveContainer>
  );
};

export default WithHeader( observer( CreateAccount ), { showLoginButton: false } );
