import { makeAutoObservable } from 'mobx';
import { Facility, MembershipPlan } from '../entities';
import { SwishError } from '../errors';
import { handleError } from '../helpers/errors';
import { FetchMembershipPlansInteractor } from '../interactors';
import { MembershipPlansStore } from '../stores';

interface ViewMembershipsViewParams {
  fetchingMembershipPlansWith?: FetchMembershipPlansInteractor;
  storingMembershipPlansOn?: MembershipPlansStore;
}

class ViewMembershipsView {
  private selectedFacilityId : number | null;

  private fetchMembershipPlans: FetchMembershipPlansInteractor;

  private membershipPlansStore : MembershipPlansStore;

  isLoading = false;

  constructor( {
    fetchingMembershipPlansWith = new FetchMembershipPlansInteractor(),
    storingMembershipPlansOn = new MembershipPlansStore(),
  } : ViewMembershipsViewParams = {} ) {
    this.selectedFacilityId = null;
    this.fetchMembershipPlans = fetchingMembershipPlansWith;
    this.membershipPlansStore = storingMembershipPlansOn;

    makeAutoObservable( this );
  }

  private planBelongsToSelectedFacility( plan : MembershipPlan ) {
    return ( plan.facility.id === this.selectedFacilityId );
  }

  private facilitiesContain( facilities: Facility[], aFacility: Facility ) {
    return facilities.some( facility => facility.id === aFacility.id );
  }

  private collectUniqueFacilities( currentFacilities: Facility[], newFacility: Facility ) {
    if ( !this.facilitiesContain( currentFacilities, newFacility ) ) {
      currentFacilities.push( newFacility );
    }

    return currentFacilities;
  }

  private get membershipPlansFacilities() {
    return this.membershipPlans.map( plan => plan.facility )
      .filter( ( facility ) => facility.sport === 'Baseball' );
  }

  private get membershipPlans() {
    return this.membershipPlansStore.allFacilitiesMembershipPlans;
  }

  private startLoading() {
    this.isLoading = true;
  }

  changeFacilityId( newFacilityId : number ) {
    this.selectedFacilityId = newFacilityId;
  }

  async fetchMemberships() {
    this.startLoading();
    try {
      await this.fetchMembershipPlans.execute();
      if ( this.facilitiesToDisplay.length > 0 ) {
        this.changeFacilityId( this.facilitiesToDisplay[0].id );
      }
    } catch ( error ) {
      handleError( error as SwishError );
    } finally {
      this.finishLoading();
    }
  }

  get membershipsToDisplay() {
    return this.membershipPlans
      .filter( plan => this.planBelongsToSelectedFacility( plan ) )
      .sort( ( a, b ) => a.price - b.price );
  }

  get facilitiesToDisplay() {
    return this.membershipPlansFacilities
      .reduce(
        (
          uniqueFacilities : Facility[],
          facility: Facility,
        ) => this.collectUniqueFacilities( uniqueFacilities, facility ),
        [],
      );
  }

  onFacilityChange = ( newFacilityId : string ) => {
    this.changeFacilityId( +newFacilityId );
  }

  finishLoading() {
    this.isLoading = false;
  }
}

export default ViewMembershipsView;
