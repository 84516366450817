import React from 'react';
import cn from 'classnames';
import { ConfirmationDialog } from '..';
import styles from './styles.module.scss';

interface DeletePlayerConfirmationDialogProps {
  open: boolean;
  playerName: string;
  onConfirm: () => void;
  onClose: () => void;
}

const DeletePlayerConfirmationDialog :
  React.FC<DeletePlayerConfirmationDialogProps> = (
    { playerName, ...others },
  ) => {
    const bodyComponent = (
      <p className={cn( 'body1', styles.dialogBodyText )}>
        You are about to delete
        {' '}
        <b>{playerName}</b>
        {' '}
        as a player in your account. All personal information and memberships will be erased
      </p>
    );

    return (
      <ConfirmationDialog
        title="No more IVL for this player"
        body={bodyComponent}
        confirmationButtonText="delete player"
        cancelButtonText="Cancel"
        {...others}
      />
    );
  };

export default DeletePlayerConfirmationDialog;
