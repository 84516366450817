import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import Alert from '@mui/material/Alert';
import { Button, Loading } from '../../../../components';
import {
  CREATE_ACCOUNT_URL,
  HOME_URL,
} from '../../../../constants/routes';
import styles from '../../styles.module.scss';
import { LoginView } from '../../../../views';
import { LoginForm, MembershipPlansByFacility } from '..';
import ViewMembershipsView from '../../../../views/ViewMembershipsView';

interface MainSectionProps {
  loginView: LoginView;
  membershipView: ViewMembershipsView;
  successfullyDeletedAccount: boolean;
}

const MainSection: React.FC<MainSectionProps> = ( {
  loginView,
  membershipView,
  successfullyDeletedAccount,
} ) => {
  const history = useHistory();
  const handleGetStartedClick = () => history.push( CREATE_ACCOUNT_URL );

  const [ showAlert, setShowAlert ] = useState( successfullyDeletedAccount );

  useEffect( () => {
    if ( loginView.loginSuccessful ) {
      history.replace( HOME_URL );
    }
  }, [ loginView.loginSuccessful, history ] );

  useEffect( () => {
    membershipView.fetchMemberships();
  }, [ membershipView ] );

  const renderLoader = useCallback( () => (
    <div className={styles.loaderContainer}>
      <Loading type="Oval" />
    </div>
  ), [] );

  return (
    <>
      <div className={styles.mainSectionContainer}>
        {
          ( showAlert )
            && (
            <Alert
              className={styles.alertBanner}
              variant="filled"
              severity="success"
              onClose={() => { setShowAlert( false ); }}
            >
              {'You\'ve succesfully deleted your account. See you around!'}
            </Alert>
            )
        }

        <div>
          <div className={styles.textWrapper}>
            <h2 className={styles.header1}>The Ultimate Baseball Training Facility</h2>
            <p className={styles.body1}>
              Setup your account and start booking sessions for you or your players
            </p>
          </div>
          <div className={styles.btnContainer}>
            <Button
              onClick={handleGetStartedClick}
              label="GET STARTED"
            />
          </div>

          <p className="subheader">Already a member?</p>
          <LoginForm
            onSubmit={loginView.login}
            validate={loginView.validateForm}
            errorMessage={loginView.errorMessage}
          />
        </div>

      </div>
      <div className={styles.viewMembershipsContainer}>
        <h1 className={styles.megaHeader}> Choose a facility and get your plan </h1>
        { membershipView.isLoading
          ? renderLoader()
          : (
            <MembershipPlansByFacility
              facilities={membershipView.facilitiesToDisplay}
              membershipPlans={membershipView.membershipsToDisplay}
              onFacilityChange={membershipView.onFacilityChange}
              onMembershipClick={handleGetStartedClick}
            />
          ) }
      </div>
    </>
  );
};

export default observer( MainSection );
